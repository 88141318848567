import React, { useEffect, useRef, useState } from "react";
import ChatBox from "./components/ChatBox";
import UserList from "./components/UserList";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useSocket } from "../../Hooks/SocketProvider";
import { Box } from "@mui/material";
import Iconify from "../../components/Iconify";
import {
  _uplaod_image_audio_s3,
  _upload_image_s3_api,
} from "../../DAL/SupportTicket";
import { useAdminContext } from "../../Hooks/AdminContext";
import { s3BaseUrl } from "../../config/config";

const Chat = () => {
  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : ""
  );
  const userListRef = useRef(null);
  const { socket, setUnreadNotifications, notifications, setNotifications } =
    useSocket();
  const { userInfo } = useAdminContext();
  const [activeRow, setActiveRow] = useState(null);
  const [isChatExist, setIsChatExist] = useState(true);
  const [previews, setPreviews] = useState("");
  const [previewsOldPath, setPreviewsOldPath] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [delLoading, setDelLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [fileImage, setFileImage] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [userInformation, setUserInformation] = useState({});
  const [isloadmessage, setIsloadmessage] = useState(false);
  const [ChatID, setChatID] = useState(null);
  const usersRef = useRef(users);
  const messagesRef = useRef(messages);
  const ChatIDRef = useRef(ChatID);
  const userInformationRef = useRef(userInformation);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  const [inputs, setInputs] = useState({
    message: "",
    status: true,
  });
  const userId = userInfo?.user_id?._id;

  const handleClickUpdateCancel = () => {
    setEditRow(null);
    setInputs((values) => ({ ...values, ["message"]: "" }));
  };

  const handleClickEdit = (row) => {
    setEditRow(row);
    setInputs((values) => ({ ...values, ["message"]: row?.message_content }));
    setPreviews(row?.image ? s3BaseUrl + row?.image : "");
    setPreviewsOldPath(row?.image);
  };

  const handleAgreeDelete = (row) => {
    setOpenDelete(true);
    setDeleteRow(row);
  };

  const handleChatList = () => {
    if (userListRef.current) {
      userListRef.current.get_chat_list();
    }
  };
  const handleNotificationAddFromNewMsg = (message) => {
    try {
      if (!message) {
        console.log("message is undefined");
        return;
      }
      let newNotifications = [message?.notification, ...notifications];
      setNotifications(newNotifications);
      setUnreadNotifications(message?.unseen_notification_count);
    } catch (error) {
      console.error(
        "Unexpected error in handleNotificationAddFromNewMsg:",
        error.message
      );
    }
  };

  const handleMarkAsReadMessage = (lastMessage) => {
    const userId = userInformationRef.current?.user_id;

    try {
      if (!lastMessage) {
        console.error("lastMessage is undefined");
        return;
      }

      const lastMessageID = lastMessage?._id;
      const lastChatID = lastMessage?.chat_id;
      const senderUserID = lastMessage?.sender?.user_id;

      if (lastMessageID && lastChatID && senderUserID) {
        if (senderUserID == userId && lastChatID == ChatIDRef.current) {
          const postMessage = {
            user_id: senderUserID,
            chat_id: lastChatID,
            message_id: lastMessageID,
          };

          if (socket && postMessage) {
            socket.emit("mark_as_read_message", postMessage);
          }
        }
      }
    } catch (error) {
      console.error(
        "Unexpected error in handleMarkAsReadMessage:",
        error.message
      );
    }
  };

  const handleDelete = async () => {
    if (deleteRow) {
      let postMessage = {
        chat_id: deleteRow?.chat_id,
        message_id: deleteRow?._id,
      };

      console.log(
        "postMessage ___result  _delete_chat_message_event_for_sender",
        postMessage
      );

      if (socket && postMessage) {
        socket.emit("delete_chat_message", postMessage);
      }
    } else {
      setOpenDelete(false);
      setDelLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setSubmitButtonLoading(true);
    let imagePath = previewsOldPath;
    let audioPath = "";
    let message_content_type = "general";
    let receiver_id = "";
    let audioDuration = 0;

    if (audioFile) {
      audioDuration = secondsElapsed ? secondsElapsed : 0;
      let formData = new FormData();
      formData.append("audio", audioFile.blob, audioFile.name); // Use the name here

      let result = await _uplaod_image_audio_s3(formData, "audio");
      console.log("result  _uplaod_image_audio_s3", result);
      if (result.code == 200) {
        audioPath = result?.path;
      }
    }

    if (fileImage) {
      let formData = new FormData();
      formData.append("image", fileImage);
      let result = await _uplaod_image_audio_s3(formData, "image");
      console.log("result  _uplaod_image_audio_s3", result);
      if (result.code == 200) {
        imagePath = result?.path;
      }
    } else {
      if (previews == "") {
        imagePath = "";
      }
    }

    if (
      inputs?.message.trim() != "" ||
      previews != "" ||
      fileImage ||
      audioPath
    ) {
      // general, image, audio, video, document

      if (imagePath != "") {
        message_content_type = "image";
      }

      if (audioPath != "") {
        message_content_type = "audio";
      }

      if (activeRow?.participant?.length > 0) {
        const newUser = activeRow.participant.find(
          (participant) => participant?.action_user === 0
        );

        receiver_id = newUser?.user_id;
      }

      let postMessage = {
        receiver_id: receiver_id,
        x_sh_auth: token,
        message_content_type: message_content_type,
        message_content: inputs?.message,
        image: imagePath,
        audio_duration: audioDuration,
        audio_url: audioPath,
      };

      if (editRow) {
        let postMessage = {
          message_content: inputs?.message,
          image: imagePath,
          message_id: editRow?._id,
        };

        if (socket && postMessage) {
          socket.emit("update_chat_message", postMessage);
          setTimeout(() => {
            setSubmitButtonLoading(false);
          }, 3000);
        }
      } else {
        if (socket && postMessage) {
          console.log("postMessage  ___postMessage  ____socket", postMessage);
          socket.emit("send_chat_message", postMessage);
          setTimeout(() => {
            setSubmitButtonLoading(false);
          }, 3000);
        }
      }
    } else {
      setTimeout(() => {
        setSubmitButtonLoading(false);
      }, 1000);
    }
    setPreviews("");
    setAudioFile(null);
    setInputs((values) => ({ ...values, message: "" }));
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    ChatIDRef.current = ChatID;
  }, [ChatID]);

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    if (userInformation?.user_id) {
      localStorage.setItem("adminUserID", userInformation?.user_id);
    }

    userInformationRef.current = userInformation;
  }, [userInformation]);

  const showNotification = (title, body, icon) => {
    console.log("Attempting to show notification...");
    if (Notification.permission === "granted") {
      try {
        new Notification(title || "New Notification", {
          body: body || "You have a new notification!",
          icon: icon || "",
        });
        console.log("Notification should be displayed now.");
      } catch (error) {
        console.error("Error displaying notification:", error);
      }
    } else if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          try {
            new Notification(title || "New Notification", {
              body: body || "You have a new notification!",
              icon: icon || "",
            });
            console.log("Notification should be displayed now.");
          } catch (error) {
            console.error("Error displaying notification:", error);
          }
        } else {
          console.log("Notification permission not granted.");
          alert("Please allow notifications to receive updates.");
        }
      });
    } else {
      console.log("Notification permission denied.");
      alert(
        "Notifications are blocked. Please enable them in your browser settings."
      );
    }
  };

  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        console.log("Notification permission:", permission);
      });
    } else {
      console.log(
        "Notification permission already set to:",
        Notification.permission
      );
    }
  }, []);

  // Add Msg for_sender
  useEffect(() => {
    socket.on("send_chat_message_event_for_sender", (result) => {
      if (result?.code === 200) {
        new Promise((resolve) => {
          let newMsg = [...messagesRef.current, result?.message_add];
          setMessages(newMsg);
          messagesRef.current = newMsg;

          // setMessages((prev) => [...prev, result?.message_add]);
          const newList = usersRef.current.map((item) => {
            if (item._id === result?.chat?._id) {
              return {
                ...item,
                ...result?.chat,
              };
            }
            return item;
          });

          setUsers(newList);
          resolve();
        }).then(() => {
          // setMessages((prev) => [...prev, result?.message_add]);
        });

        // setMessages((prev) => [...prev, result?.message_add]);
      }
    });

    socket.on("send_chat_message_receiver", (result) => {
      console.log("result   ___send_chat_message_receiver", result);
      console.log("users   ___send_chat_message_receiver", users);

      if (result?.code === 200) {
        let msgSenderID = result?.message_add?.sender?.user_id;
        let activeUserID = userInformationRef.current?.user_id;
        console.log(
          "msgSenderID __________ID  ___send_chat_message_receiver",
          msgSenderID
        );
        console.log(
          "activeUserID   __________ID ___send_chat_message_receiver",
          activeUserID
        );

        new Promise((resolve) => {
          const foundItem = usersRef.current.find(
            (item) => item._id === result?.chat?._id
          );
          if (foundItem) {
            let newList = usersRef.current.map((item) => {
              if (item._id === result?.chat?._id) {
                return {
                  ...item,
                  ...result?.chat,
                };
              }
              return item;
            });
            setUsers(newList);
          } else {
            let newList = [result?.chat, ...usersRef.current];
            setUsers(newList);
          }

          resolve();
        }).then(() => {
          if (msgSenderID === activeUserID) {
            // setMessages((prev) => [...prev, result?.message_add]);
            let newMsg = [...messagesRef.current, result?.message_add];
            setMessages(newMsg);
            messagesRef.current = newMsg;
          }
          let msgTitle = `Message From | ${result?.message_add?.sender?.first_name} ${result?.message_add?.sender?.last_name}`;
          let msgBody = `${result?.message_add?.message_content}`;
          let msgIcon = "";

          if (result?.message_add?.message_content_type == "image") {
            msgBody = `Send a image`;
          } else if (result?.message_add?.message_content_type == "audio") {
            msgBody = `Send a Voice message`;
          }

          if (result?.message_add?.sender?.profile_image) {
            msgIcon = s3BaseUrl + result?.message_add?.sender?.profile_image;
          }

          showNotification(msgTitle, msgBody, msgIcon);
          handleMarkAsReadMessage(result?.message_add);
          handleNotificationAddFromNewMsg(result);
        });
      }
    });

    socket.on("mark_as_read_message_event_for_sender", (result) => {
      console.log("result   ___mark_as_read_message_event_for_sender", result);

      if (result?.code === 200) {
        new Promise((resolve) => {
          const newList = messagesRef.current.map((item) => {
            if (item?.sender?.user_id !== userId) {
              return {
                ...item,
                status: "read",
              };
            }

            return item;
          });

          console.log(
            "newList   __ newList __ newList __ newList ___mark_as_read_message_receiver",
            newList
          );
          setMessages(newList);
          resolve();
        }).then(() => {
          // setEditRow(null);
        });

        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            if (item._id === result?.chat?._id) {
              return {
                ...item,
                ...result?.chat,
              };
            }
            return item;
          });

          setUsers(newList);
          resolve();
        }).then(() => {});
      }
    });

    socket.on("mark_as_read_message_receiver", (result) => {
      let activeUserID = userInformationRef.current?.user_id;

      console.log("result   ___mark_as_read_message_receiver", result);
      console.log(
        "activeUserID   ___mark_as_read_message_receiver",
        activeUserID
      );
      console.log(
        "messagesRef.current   ___mark_as_read_message_receiver",
        messagesRef.current
      );
      if (result?.code === 200) {
        if (ChatIDRef.current == result?.chat?._id) {
          new Promise((resolve) => {
            const newList = messagesRef.current.map((item) => {
              if (item?.sender?.user_id == userId) {
                return {
                  ...item,
                  status: "read",
                };
              }

              return item;
            });

            console.log(
              "newList   __ newList __ newList __ newList ___mark_as_read_message_receiver",
              newList
            );
            setMessages(newList);
            resolve();
          }).then(() => {
            // setEditRow(null);
          });
        }

        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            if (item._id === result?.chat?._id) {
              return {
                ...item,
                ...result?.chat,
              };
            }
            return item;
          });

          setUsers(newList);
          resolve();
        }).then(() => {});
      }
    });

    // Online check
    socket.on("user_online", (result) => {
      if (result?.user_type == 0) {
        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            let newParticipant = item.participant.map((_participant) => {
              if (_participant?.user_id == result?.user_id) {
                return {
                  ..._participant,
                  is_online: true,
                };
              }
              return _participant;
            });

            return {
              ...item,
              participant: newParticipant,
            };
          });
          setUsers(newList);
          resolve();
        });
      }
    });

    // Offline check
    socket.on("user_offline", (result) => {
      if (result?.user_type == 0) {
        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            let newParticipant = item.participant.map((_participant) => {
              if (_participant?.user_id == result?.user_id) {
                return {
                  ..._participant,
                  is_online: false,
                };
              }
              return _participant;
            });

            return {
              ...item,
              participant: newParticipant,
            };
          });
          setUsers(newList);
          resolve();
        });
      }
    });
    // Clean up the socket connection on component unmount
    return () => {
      socket.off("send_chat_message_event_for_sender");
      socket.off("send_chat_message_receiver");
      socket.off("mark_as_read_message_event_for_sender");
      socket.off("mark_as_read_message_receiver");
      socket.off("user_online");
      socket.off("user_offline");
    };
  }, [socket]);

  // After Add Msg and reload chat api
  useEffect(() => {
    socket.on("send_chat_message_event_for_sender", (result) => {
      if (result?.code === 200) {
        if (isChatExist == false) {
          console.log("isChatExist  _socket __isChatExist", isChatExist);
          handleChatList(); // its call when chat is not exist to add new user in list
        }
      }
    });
  }, [socket, isChatExist]);

  // Update Msg
  useEffect(() => {
    socket.on("update_chat_message_event_for_sender", (result) => {
      if (result?.code === 200) {
        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            if (item._id === result?.chat?._id) {
              return {
                ...item,
                ...result?.chat,
              };
            }
            return item;
          });

          setUsers(newList);
          resolve();
        }).then(() => {});

        new Promise((resolve) => {
          const newList = messages.map((item) => {
            if (item._id === result?.message_update?._id) {
              return result?.message_update;
            }
            return item;
          });

          setMessages(newList);
          resolve();
        }).then(() => {
          setEditRow(null);
        });
      }
    });

    socket.on("update_chat_message_receiver", (result) => {
      console.log("result   __result  ___update_chat_message_receiver", result);
      if (result?.code === 200) {
        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            if (item._id === result?.chat?._id) {
              return {
                ...item,
                ...result?.chat,
              };
            }
            return item;
          });

          setUsers(newList);
          resolve();
        }).then(() => {});

        new Promise((resolve) => {
          const newList = messages.map((item) => {
            if (item._id === result?.message_update?._id) {
              return result?.message_update;
            }
            return item;
          });
          setMessages(newList);
          resolve();
        }).then(() => {
          setEditRow(null);
        });
      }
    });
  }, [socket, messages, editRow]);

  // Delete Msg
  useEffect(() => {
    if (deleteRow) {
      socket.on("delete_chat_message_event_for_sender", (result) => {
        console.log(
          "result ___result  _delete_chat_message_event_for_sender",
          result
        );
        if (result?.code === 200) {
          new Promise((resolve) => {
            const newList = usersRef.current.map((item) => {
              if (item._id === result?.chat?._id) {
                return {
                  ...item,
                  ...result?.chat,
                };
              }
              return item;
            });

            setUsers(newList);
            resolve();
          }).then(() => {});

          new Promise((resolve) => {
            let newMessageList = messages.filter(
              (msg) => msg._id !== deleteRow?._id
            );

            setMessages(newMessageList);

            resolve();
          }).then(() => {
            setDeleteRow(null);
            setOpenDelete(false);
            setDelLoading(false);
          });
        } else {
          setOpenDelete(false);
          setDelLoading(false);
        }
      });
    } else {
      setOpenDelete(false);
      setDelLoading(false);
    }

    socket.on("delete_chat_message_receiver", (result) => {
      console.log(
        "result ___result __delete_chat_message_receiver delete_chat_message_receiver",
        result
      );
      if (result?.code === 200) {
        new Promise((resolve) => {
          const newList = usersRef.current.map((item) => {
            if (item._id === result?.chat?._id) {
              return {
                ...item,
                ...result?.chat,
              };
            }
            return item;
          });

          setUsers(newList);
          resolve();
        }).then(() => {});

        new Promise((resolve) => {
          let newMessageList = messages.filter(
            (msg) => msg._id !== result?.message_id
          );

          setMessages(newMessageList);

          resolve();
        }).then(() => {
          // setDeleteRow(null);
          // setOpenDelete(false);
          // setDelLoading(false);
        });
      }
    });
  }, [socket, messages, deleteRow]);

  useEffect(() => {
    if (editRow) {
      setEditRow(null);
    }
    if (deleteRow) {
      setDeleteRow(null);
    }
  }, [messages]);

  useEffect(() => {
    console.log("messages ____messages", messages);
  }, [messages]);

  useEffect(() => {
    console.log("isChatExist    __isChatExist", isChatExist);
  }, [isChatExist]);

  useEffect(() => {
    console.log("editRow ____editRow", editRow);
  }, [editRow]);

  useEffect(() => {
    if (audioFile) {
      console.log("audioFile before __audioFile", audioFile);
      handleSubmit();
    }
  }, [audioFile]);

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this message?"}
        handleAgree={handleDelete}
      />

      <div className="row" style={{ backgroundColor: "#fff" }}>
        <div className=" col-lg-5 col-xl-4 mb-4 mb-md-0">
          <UserList
            ref={userListRef}
            users={users}
            setUsers={setUsers}
            activeRow={activeRow}
            setActiveRow={setActiveRow}
            messages={messages}
            setMessages={setMessages}
            isChatExist={isChatExist}
            setIsChatExist={setIsChatExist}
            isloadmessage={isloadmessage}
            setIsloadmessage={setIsloadmessage}
            ChatID={ChatID}
            setChatID={setChatID}
          />
        </div>

        <div
          className="col-lg-7 col-xl-8"
          style={{
            borderRadius: "0px",
            borderLeft: "1px solid #c4c4c4",
            // paddingBottom: "35px",
          }}
        >
          {activeRow ? (
            <ChatBox
              inputs={inputs}
              setInputs={setInputs}
              previews={previews}
              setPreviews={setPreviews}
              handleSubmit={handleSubmit}
              MENU_OPTIONS={MENU_OPTIONS}
              users={users}
              user={activeRow}
              activeRow={activeRow}
              setActiveRow={setActiveRow}
              messages={messages}
              setMessages={setMessages}
              editRow={editRow}
              handleClickUpdateCancel={handleClickUpdateCancel}
              fileImage={fileImage}
              setFileImage={setFileImage}
              userInformation={userInformation}
              setUserInformation={setUserInformation}
              isloadmessage={isloadmessage}
              setIsloadmessage={setIsloadmessage}
              socket={socket}
              audioFile={audioFile}
              setAudioFile={setAudioFile}
              submitButtonLoading={submitButtonLoading}
              setSubmitButtonLoading={setSubmitButtonLoading}
              setSecondsElapsed={setSecondsElapsed}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "90vh",
                // borderLeft: "1px solid #c4c4c4",
              }}
            >
              {/* <IconButton onClick={handleClickReset} color="primary"> */}

              <Iconify
                icon="mingcute:message-4-line"
                width={150}
                height={150}
              />
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
