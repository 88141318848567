import React, { createRef, useEffect, useState } from "react";
import ThemeConfig from "./theme";
import Routers from "./routes";
import { Slide } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet-async";

import "./assets/css/style.css";
import "react-phone-input-2/lib/style.css";
import "react-tagsinput/react-tagsinput.css";

import { ContextAdmin } from "./Hooks/AdminContext";
import { s3BaseUrl } from "./config/config";
import { SocketProvider } from "./Hooks/SocketProvider";

function App() {
  const [adminSetting, setAdminSetting] = useState({});
  const notistackRef = createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    const gernal_settings = localStorage.getItem("gernal_settings");
    if (gernal_settings) {
      setAdminSetting(JSON.parse(gernal_settings));
    }
  }, [localStorage.getItem("gernal_settings")]);

  return (
    <ContextAdmin>
      <SocketProvider>
        <ThemeConfig>
          <SnackbarProvider
            ref={notistackRef}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            TransitionComponent={Slide}
            maxSnack={3}
            action={(key) => (
              <div onClick={onClickDismiss(key)} className="pointer">
                <i className="fa-solid fa-xmark me-3"></i>
              </div>
            )}
          >
            <Helmet>
              <title>{adminSetting?.meta_title}</title>
              <meta
                name="description"
                content={adminSetting?.meta_discription}
              />
              <link rel="icon" href={s3BaseUrl + adminSetting?.admin_favicon} />
            </Helmet>
            <Routers />
          </SnackbarProvider>
        </ThemeConfig>
      </SocketProvider>
    </ContextAdmin>
  );
}

export default App;
