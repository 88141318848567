import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { baseUrl } from "../config/config";
import { enqueueSnackbar } from "notistack";
import { _get_list_notification } from "../DAL/Notification/Notification";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

let socket = io();

export const SocketProvider = ({ children = null }) => {
  const [startSocket, setStartSocket] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [notifications, setNotifications] = useState([]);
  const [notificationsPart2, setNotificationsPart2] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [loading, setLoading] = useState(true);

  const socketON = () => {
    setStartSocket(true);
  };

  const socketOFF = () => {
    setStartSocket(false);
    // socket.disconnect();
  };

  const connectSocket = () => {
    console.log("connectingSocket");
    const user_socket_id = JSON.parse(localStorage.getItem("user"))?.user_id
      ?._id;
    const ENDPOINT = `${baseUrl}?user_id=${user_socket_id}`;
    socket = io(ENDPOINT);
  };

  const fetchNotificationListing = async (limit = 10) => {
    // const formData = new FormData();
    setLoading(true);
    const result = await _get_list_notification(limit);
    if (result.code === 200) {
      // console.log("notificationnotificationnotificationnotification", result);
      setNotifications(result.notifications);
      setNotificationsPart2(result.notifications);

      setUnreadNotifications(result.unseen_count);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startSocket) {
      connectSocket();
    }
  }, [startSocket]);

  return (
    <SocketContext.Provider
      value={{
        loading,
        notifications,
        setNotifications,
        unreadNotifications,
        setUnreadNotifications,
        fetchNotificationListing,
        socketON,
        socketOFF,
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
