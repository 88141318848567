import React, { useEffect, useState } from "react";
import CustomTabs from "../../../components/CustomTabs";
import {
  _get_exist_chat,
  _get_online_offline_list_business_for_chat,
} from "../../../DAL/Chat/Chat";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import OnlineOfflineUsercomponent from "./OnlineOfflineUsercomponent";
import { useSnackbar } from "notistack";

const UserOnlineOfflineList = ({
  users,
  setUsers,
  activeRow,
  setActiveRow,
  messages,
  setMessages,
  setIsOpenDrawer,
  isChatExist,
  setIsChatExist,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(true);
  const [onlineBusinessList, setOnlineBusinessList] = useState([]);
  const [offlineBusinessList, setOfflineBusinessList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const get_exist_chat = async (newUser) => {
    // setIsLoading(true);
    const result = await _get_exist_chat(newUser?.user_id);
    console.log("result  ___result  _get_exist_chat _get_exist_chat", result);
    console.log("result  _get_exist_chat __newUser", result);
    if (result.code === 200) {
      setIsChatExist(result?.is_chat_exist);
      // setUsers(result?.chat_list);
      // const filteredData = result?.filter(item => item.action_user === 1 || item.action_user === 2);
      // setOnlineBusinessList(result?.online_business_list);
      // setOfflineBusinessList(result?.offline_business_list);
      // const chip_data = { ...filter_data };
      // setChipData(chip_data);
      // const data = result.support_tickets.map((item) => {
      //   return {
      //     ...item,
      //   };
      // });
      // setTicketList(data);
      // setFirstTimePageLoad(false);
      // setIsTabLoading(false);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const addUserIfNotExists = (newUser) => {
    console.log("users  __newUser", users);
    console.log("newUser  __newUser", newUser);

    let newActiveRow = {
      // _id: "6729ab79ab69992440b24a3c",
      participant: [
        {
          ...newUser,
          unread_message_count: 0,
          is_chat_initiated: false,
          profile_image: newUser?.image,
        },
      ],
      last_message: {},
    };
    setActiveRow(newActiveRow);
    get_exist_chat(newUser);
    setIsOpenDrawer(false);
    // const existingUser = users.find((user) => user._id === newUser._id);

    // if (!existingUser) {
    //   setUsers((prevUsers) => [...prevUsers, newUser]);
    //   setMessages((prevMessages) => [...prevMessages, []]);
    // }
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //   <Box sx={{ display: 'flex' }}>
  //   <CircularProgress />
  // </Box>

  // {
  //   title: "Transactions",
  //   component: isTabLoading ? (
  //     <div style={{ minHeight: "300px" }}>
  //       <CircularProgress className={classes.loading} color="primary" />
  //     </div>
  //   ) : (
  //     <>
  //       <div style={{ minHeight: "300px" }}>
  //         <Transaction tabData={tabData} />
  //       </div>
  //     </>
  //   ),
  // },

  const TABS_OPTIONS = [
    {
      title: "Online",
      component: isTabLoading ? (
        <CustomCircularProgress minHeight="300px" />
      ) : (
        <>
          {/* <div style={{ minHeight: "300px" }}> */}
          <OnlineOfflineUsercomponent
            users={onlineBusinessList}
            addUserIfNotExists={addUserIfNotExists}
            is_online={true}
          />
          {/* </div> */}
        </>
      ),
    },

    {
      title: "Offline",
      component: isTabLoading ? (
        <CustomCircularProgress minHeight="300px" />
      ) : (
        <>
          <OnlineOfflineUsercomponent
            users={offlineBusinessList}
            addUserIfNotExists={addUserIfNotExists}
            is_online={false}
          />
        </>
      ),
    },
  ];

  const get_online_offline_list_business_for_chat = async (_tabValue) => {
    let selectedTabValue = _tabValue ? _tabValue : tabValue;
    let type = "online";
    let search = "";
    if (selectedTabValue != 0) {
      type = "offline";
    }

    setIsTabLoading(true);
    const result = await _get_online_offline_list_business_for_chat(
      type,
      searchTerm
    );
    console.log("result  ___result", result);
    if (result.code === 200) {
      setOnlineBusinessList(result?.online_business_list);
      setOfflineBusinessList(result?.offline_business_list);

      // const chip_data = { ...filter_data };
      // setChipData(chip_data);
      // const data = result.support_tickets.map((item) => {
      //   return {
      //     ...item,
      //   };
      // });
      // setTicketList(data);
      // setIsLoading(false);
      setFirstTimePageLoad(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsTabLoading(false);
    }
  };

  useEffect(() => {
    get_online_offline_list_business_for_chat(tabValue);
  }, [tabValue]);

  useEffect(() => {
    if (!firstTimePageLoad) {
      const timeoutId = setTimeout(() => {
        get_online_offline_list_business_for_chat(tabValue);
      }, 700);

      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);

  return (
    <div className="mt-2">
      <div className="input-group rounded p-2 pb-0">
        <input
          type="search"
          className="form-control rounded textarea-custom"
          placeholder="Search"
          aria-label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <CustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
      />
    </div>
  );
};

export default UserOnlineOfflineList;
