import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import personFill from "@iconify/icons-eva/person-fill";
import { useLocation, useNavigate } from "react-router-dom";
import unlockFill from "@iconify/icons-eva/unlock-fill";
import { Button, Box, MenuItem, Typography, Avatar } from "@mui/material";
import { useEffect } from "react";
import ChangePassword from "../../components/ChangePassword";
import { s3BaseUrl } from "../../config/config";
import { useAdminContext } from "../../Hooks/AdminContext";
import { UserIcon } from "../../assets";
import ProfilePopover from "../../components/ProfilePopover";
import {
  _logout_api,
  _logout_from_all_this_devices,
} from "../../DAL/Login/Login";
import { useSnackbar } from "notistack";
import LogoutConfirmation from "../../components/LogoutConfirmation";
import { useSocket } from "../../Hooks/SocketProvider";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Edit Profile",
    icon: personFill,
    linkTo: "/edit-profile",
  },
];

export default function AccountPopover({ setIsLoading }) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { socket, socketOFF } = useSocket();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useAdminContext();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("this");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };

  const handleProfile = (value) => {
    navigate(value);
    handleClose();
  };

  const handleLogout = async () => {
    let postData = {
      logout_from: selectedOption,
    };

    setIsLoading(true);
    const result = await _logout_from_all_this_devices(postData);
    if (result.code === 200) {
      let postSocketData = {
        user_id: userInfo?.user_id?._id,
      };
      if (socket && postMessage) {
        socket.emit("off_line", postSocketData);

        console.log("connectingSocket  off_line");
        console.log("connectingSocket  off_line", postSocketData);
      }
      socketOFF();
      navigate(`/login`);
      localStorage.clear();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const is_path_includes = (path) => {
        return pathname.toString().includes(path);
      };
      if (!is_path_includes("/busines-clients")) {
        localStorage.removeItem("client_search_text");
      }
      if (!is_path_includes("/users")) {
        localStorage.removeItem("user_search_text");
      }
      if (!is_path_includes("/reports")) {
        localStorage.removeItem("user_filter_data");
      }
    }, 1000);
  }, [pathname]);

  return (
    <>
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />

      <LogoutConfirmation
        openLogoutModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        handleLogout={handleLogout}
      />

      <div
        className="d-flex align-items-center position-relative pointer"
        onClick={handleOpen}
      >
        <div className="ms-3 profile_avatar">
          <Avatar
            sx={{ width: 34, height: 34 }}
            src={userInfo?.image ? s3BaseUrl + userInfo?.image : UserIcon}
          />
        </div>
        <span className="d-flex align-items-center user-name-text ms-2 text-nowrap">
          {userInfo?.first_name + " " + userInfo?.last_name}
          <i className="fa-solid fa-chevron-down ms-2 fs-12 "></i>
        </span>
      </div>

      <ProfilePopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userInfo?.first_name + " " + userInfo?.last_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userInfo?.user_id?.email}
          </Typography>
        </Box>
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleProfile(option.linkTo)}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            handleClose();
            setopenPasswordModal(true);
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={unlockFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Change Password
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth variant="outlined" onClick={handleOpenLogoutModal}>
            Logout
          </Button>
        </Box>
      </ProfilePopover>
    </>
  );
}
