import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import {
  LinearProgress,
  Typography,
  Stack,
  IconButton,
  Box,
} from "@mui/material";

const VoiceRecorder = forwardRef((props, ref) => {
  const {
    setAudioFile,
    isRecording,
    setIsRecording,
    isStartRecording,
    setIsStartRecording,
    isStopRecording,
    setIsStopRecording,
    setInputs,
    setSubmitButtonLoading,
    setSecondsElapsed,
  } = props;

  const [elapsedTime, setElapsedTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const intervalRef = useRef(null);
  const isCancelRef = useRef(false);

  const startRecording = async () => {
    try {
      setIsRecording(true);
      setElapsedTime(0);
      setSecondsElapsed(0);
      isCancelRef.current = false;

      setInputs((values) => ({
        ...values,
        message_content_type: "audio",
      }));

      // Check if microphone permission is granted
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });

      // If permission is denied, request it
      if (permissionStatus.state === "denied") {
        alert(
          "Microphone access is denied. Please enable microphone access in your browser settings."
        );
        setIsRecording(false);
        cancelRecording();
        return;
      }

      // Request audio input if permission is granted
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      if (stream) {
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/mp3",
          });
          const audioFileName = `audio_${Date.now()}.mp3`;

          if (isCancelRef.current) {
            setAudioFile(null);
          } else {
            setAudioFile({ blob: audioBlob, name: audioFileName });
          }

          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        // intervalRef.current = setInterval(
        //   () => setElapsedTime((prev) => prev + 1),
        //   1000
        // );
        intervalRef.current = setInterval(() => {
          setElapsedTime((prev) => {
            const newElapsedTime = prev + 1;
            setSecondsElapsed(newElapsedTime);
            return newElapsedTime;
          });
        }, 1000);
      }
    } catch (error) {
      // If there is any error (like permission denied), reset the UI state
      setIsRecording(false);
      cancelRecording();
      console.error("Error accessing microphone:", error);
      alert("Could not access microphone. Please check permissions.");
    }
  };

  const stopRecording = () => {
    setSubmitButtonLoading(true);
    setIsRecording(false);

    // Stop the media recorder if it exists
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }

    // Stop the media stream to release the microphone
    if (mediaRecorderRef?.current && mediaRecorderRef?.current?.stream) {
      mediaRecorderRef?.current?.stream
        .getTracks()
        .forEach((track) => track.stop());
    }

    clearInterval(intervalRef.current);
  };

  const cancelRecording = () => {
    isCancelRef.current = true;
    setAudioFile(null);
    setInputs((values) => ({
      ...values,
      message_content_type: "general",
    }));
    setIsRecording(false);
    setElapsedTime(0);
    setSecondsElapsed(0);

    clearInterval(intervalRef.current);
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useImperativeHandle(ref, () => ({
    startRecording,
  }));

  useEffect(() => {
    if (isStartRecording) {
      startRecording();
      setIsStopRecording(false);
    }
  }, [isStartRecording]);

  useEffect(() => {
    if (isStopRecording) {
      stopRecording();
      setIsStartRecording(false);
    }
  }, [isStopRecording]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ width: "100%" }}
    >
      {isRecording && (
        <IconButton onClick={cancelRecording} color="error">
          <CloseIcon />
        </IconButton>
      )}

      {isRecording && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            marginLeft: 1,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={(elapsedTime % 60) * 1.67}
            sx={{ width: "100%", marginRight: 1 }}
          />
          <Typography variant="body2" sx={{ marginLeft: 1 }}>
            {formatTime(elapsedTime)}
          </Typography>
        </Box>
      )}

      <IconButton
        color="primary"
        sx={{ alignSelf: "flex-end" }}
        onClick={stopRecording}
      >
        <SendIcon />
      </IconButton>
    </Stack>
  );
});

export default VoiceRecorder;
