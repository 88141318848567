import { invokeApi } from "./invokeApi";

export const _get_list_department_api = async () => {
  const requestObj = {
    path: `api/department/list_department`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _mark_resolve_support_ticket_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket/mark_resolve_support_ticket/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_comment_with_support_ticket_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket_comment/list_comment_with_support_ticket/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_support_tickets_for_client_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket/list_support_tickets_for_client`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_support_tickets_for_client_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket/add_support_ticket`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_support_ticket_comment_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket_comment/add_support_ticket_comment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _upload_image_s3_api = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_image_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _uplaod_image_audio_s3 = async (data, type) => {
  const requestObj = {
    path: `api/app_api/upload_image_audio_s3?type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_files_from_s3 = async (data) => {
  const requestObj = {
    path: `api/app_api/delete_files_from_s3`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _empty_image_path_in_business = async (id) => {
  const requestObj = {
    path: `api/business/empty_image_path_in_business/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_support_ticket_api = async (data, id) => {
  const requestObj = {
    path: `api/support_ticket/update_support_ticket/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_support_ticket_comment_api = async (data, id) => {
  const requestObj = {
    path: `api/support_ticket_comment/update_support_ticket_comment/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket/delete_support_ticket/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket_comment_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket_comment/delete_support_ticket_comment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
