import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardLayout, LoginLayout } from "../layouts";
import {
  AddOrUpdatePayment,
  AddorUpdatedInvoice,
  AddorUpdatedTransaction,
  Dashboard,
  EditProfile,
  ExpensesReport,
  ForgotPassword,
  Invoice,
  InvoiceDetails,
  Invoices,
  Login,
  PaymentDetail,
  PaymentSummary,
  SaleReport,
  Signup,
  Taxes,
  TransactionList,
  Users,
} from "../pages";
import CheckOut from "../pages/PaymentPage/CheckOut";
import ThanksPage from "../pages/PaymentPage/ThanksPage";
import FullPageLayout from "../layouts/FullPageLayout";
import AddOrUpdateUser from "../pages/Users/AddOrUpdateUser";
import SupportTicket from "../pages/SupportTicket/SupportTicket";
import DetailSupportTicket from "../pages/SupportTicket/DetailSupportTicket";
import BankPayment from "../pages/BankPayment/BankPayment";
import LoginByAdmin from "../pages/LoginByAdmin";
import Chat from "../pages/Chat/Chat";

const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  } else {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Routers() {
  return (
    <Routes>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />

      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />

        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/invoice" element={<Invoice />} />
        <Route path="/invoice/add-invoice" element={<AddorUpdatedInvoice />} />
        <Route path="/invoice/invoice-details" element={<InvoiceDetails />} />
        <Route
          path="/invoice/edit-invoice/:id"
          element={<AddorUpdatedInvoice value="edit" />}
        />
        <Route path="/invoices" element={<Invoices />} />
        <Route
          path="/invoices/invoice-detail/:id"
          element={<PaymentDetail />}
        />
        <Route path="/invoices/add-invoice" element={<AddOrUpdatePayment />} />
        <Route
          path="/invoices/edit-invoice/:id"
          element={<AddOrUpdatePayment />}
        />

        <Route path="/taxes" element={<Taxes />} />

        <Route path="/payment-summary" element={<PaymentSummary />} />
        <Route path="/reports" element={<SaleReport />} />
        <Route path="/expenses-report" element={<ExpensesReport />} />
        <Route path="/transactions" element={<TransactionList />} />
        <Route
          path="/transactions/add-transaction"
          element={<AddorUpdatedTransaction />}
        />
        <Route
          path="/transactions/edit-transaction/:id"
          element={<AddorUpdatedTransaction value="edit" />}
        />

        <Route path="/support-tickets" element={<SupportTicket />} />
        <Route path="/support-tickets/:type" element={<SupportTicket />} />
        <Route path="/support-tickets/add-ticket" element={<SupportTicket />} />
        <Route
          path="/support-tickets/edit-ticket/:id"
          element={<SupportTicket value="edit" />}
        />
        <Route
          path="/support-tickets/detail-ticket/:type/:id"
          element={<DetailSupportTicket />}
        />

        <Route path="/users" element={<Users />} />
        <Route path="/users/add-user" element={<AddOrUpdateUser />} />
        <Route path="/users/edit-user/:user_id" element={<AddOrUpdateUser />} />

        <Route path="/bank-payment-settings" element={<BankPayment />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      <Route element={<FullPageLayout />}>
        <Route path="/create-payment/:id" element={<CheckOut />} />
        <Route path="/thanks/:id" element={<ThanksPage />} />
      </Route>
    </Routes>
  );
}
