import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import CustomAvatar from "./CustomAvatar";
import Iconify from "../../../components/Iconify";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import CustomDrawer from "../../../components/CustomDrawer";
import UserOnlineOfflineList from "./UserOnlineOfflineList";
import { useSnackbar } from "notistack";
import { _get_list_chat } from "../../../DAL/Chat/Chat";
import { s3BaseUrl } from "../../../config/config";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import moment from "moment";
import ReactMarkdown from "react-markdown";

const UserList = forwardRef((props, ref) => {
  const {
    users,
    setUsers,
    activeRow,
    setActiveRow,
    messages,
    setMessages,
    isChatExist,
    setIsChatExist,
    isloadmessage,
    setIsloadmessage,
    ChatID,
    setChatID,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredUsers, setFilteredUsers] = useState(users);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const handleClickProfile = (_user, _index) => {
    console.log("_user  ___user", _user);
    setActiveRow(_user);
    setIsChatExist(true);
    setIsloadmessage(false);
    setChatID(_user?._id);
  };

  const handleOpenFilterDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseFilterDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClickReset = () => {
    setSearchTerm("");
    get_chat_list("");
  };

  // useEffect(() => {
  //   let filtered = users;

  //   if (selectedFilter === "unread") {
  //     filtered = filtered.filter((user) => user.unread > 0);
  //   }

  //   if (searchTerm) {
  //     filtered = filtered.filter((user) =>
  //       user.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //   }

  //   setFilteredUsers(filtered);
  // }, [selectedFilter, searchTerm, users]);

  //

  const renderMessageContent = (lastMessage) => {
    const content = lastMessage?.message_content;

    if (lastMessage) {
      if (lastMessage?.message_content_type === "audio") {
        return <p>Sent a voice message</p>;
      }

      if (lastMessage?.message_content_type === "image") {
        return <p>Sent an image</p>;
      }
    }

    if (!content) return null;

    // Truncate long text content and add ellipsis
    const truncatedContent =
      content.length > 20 ? content.slice(0, 20) + " ..." : content;

    // If content is raw HTML, handle it with dangerouslySetInnerHTML
    if (/<[a-z][\s\S]*>/i.test(content)) {
      return <p dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
    }

    // If it's markdown, render it with ReactMarkdown
    return (
      <ReactMarkdown className="small text-light-show">
        {truncatedContent}
      </ReactMarkdown>
    );
  };

  const get_chat_list = async (_searchTerm) => {
    let searchList = _searchTerm == "" ? "" : searchTerm;
    // let selectedTabValue = _tabValue ? _tabValue : tabValue;
    // let type = "online";
    // let search = "";
    // if (selectedTabValue != 0) {
    //   type = "offline";
    // }

    setIsLoading(true);
    const result = await _get_list_chat(searchList);
    console.log("result  ___result  _side_list _get_list_chat", result);
    if (result.code === 200) {
      setUsers(result?.chat_list);
      // const filteredData = result?.filter(item => item.action_user === 1 || item.action_user === 2);
      // setOnlineBusinessList(result?.online_business_list);
      // setOfflineBusinessList(result?.offline_business_list);
      // const chip_data = { ...filter_data };
      // setChipData(chip_data);
      // const data = result.support_tickets.map((item) => {
      //   return {
      //     ...item,
      //   };
      // });
      // setTicketList(data);
      // setFirstTimePageLoad(false);
      // setIsTabLoading(false);
      setFirstTimePageLoad(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_chat_list();
  }, [selectedFilter]);

  useEffect(() => {
    if (!firstTimePageLoad) {
      const timeoutId = setTimeout(() => {
        get_chat_list();
      }, 700);

      return () => clearTimeout(timeoutId);
    }
  }, [searchTerm]);

  useImperativeHandle(ref, () => ({
    get_chat_list,
  }));

  return (
    <>
      <div className="p-3 px-0">
        <div className="d-flex justify-content-between w-100 mb-2">
          <div className="d-flex">
            <div
              className="text-center me-2"
              onClick={() => handleFilterChange("all")}
            >
              <span
                className={`all-messages ${
                  selectedFilter === "all" ? "selected-messages" : ""
                }`}
              >
                All
              </span>
            </div>
            <div
              className="text-center"
              onClick={() => handleFilterChange("unread")}
            >
              <span
                className={`all-messages ${
                  selectedFilter === "unread" ? "selected-messages" : ""
                }`}
              >
                Unread
              </span>
            </div>
          </div>
          <div>
            <IconButton onClick={handleClickReset} color="primary">
              <Iconify
                icon="grommet-icons:power-reset"
                width={20}
                height={20}
              />
            </IconButton>
          </div>
        </div>

        <div className="input-group rounded mb-3">
          <input
            type="search"
            className="form-control rounded textarea-custom"
            placeholder="Search"
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {isLoading ? (
          <CustomCircularProgress minHeight="63vh" />
        ) : (
          <>
            <div
              style={{
                position: "relative",
                height: "58vh",
                overflowY: "auto",
              }}
            >
              <ul className="list-unstyled mb-0">
                {users
                  .filter(
                    (user) =>
                      selectedFilter === "unread"
                        ? user.participant.some(
                            (participant) =>
                              participant.action_user === 0 &&
                              participant.unread_message_count > 0
                          )
                        : true // Show all users if selectedFilter is not "unread"
                  )
                  .map((user, index) => (
                    <li
                      key={index}
                      className={`p-2 border-bottom pointer chat-profile ${
                        user?._id === activeRow?._id
                          ? "chat-profile-active"
                          : ""
                      }`}
                      onClick={() => handleClickProfile(user, index)}
                    >
                      {user?.participant?.length > 0 &&
                        user.participant
                          .filter(
                            (participant) => participant?.action_user === 0
                          )
                          .map((participant_user, index_number) => (
                            <div
                              key={index_number}
                              className="d-flex justify-content-between"
                            >
                              <div className="d-flex flex-row">
                                <div>
                                  <CustomAvatar
                                    src={
                                      participant_user?.profile_image
                                        ? s3BaseUrl +
                                          participant_user?.profile_image
                                        : ""
                                    }
                                    alt={participant_user?.first_name}
                                    sx={{ width: 50, height: 50 }}
                                    status={participant_user?.is_online}
                                  />
                                </div>
                                <div className="pt-3">
                                  <p className="fw-bold mb-0">
                                    {participant_user?.first_name}{" "}
                                    {participant_user?.last_name}
                                  </p>
                                  <p className="small text-light-show">
                                    {renderMessageContent(user?.last_message)}
                                  </p>
                                </div>
                              </div>
                              <div className="pt-3">
                                <p className="small text-unread-show mb-1">
                                  {/* {moment(
                                  user?.last_message?.message_date_time
                                ).fromNow()} */}
                                </p>
                                {participant_user?.unread_message_count > 0 && (
                                  <span className="badge text-unread-bg rounded-pill float-end">
                                    {participant_user?.unread_message_count}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                    </li>
                  ))}
              </ul>
            </div>

            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="page-title-box me-2"> </div>

              <div className="d-flex justify-content-end gap-3 mt-1">
                {/* <IconButton
                  onClick={handleOpenFilterDrawer}
                  className="chat-add-contact"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#fff", // Set icon color to white here
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  <AddIcon />
                </IconButton> */}
              </div>
            </div>
          </>
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="New Message"
        componentToPassDown={
          <>
            <UserOnlineOfflineList
              users={users}
              setUsers={setUsers}
              activeRow={activeRow}
              setActiveRow={setActiveRow}
              messages={messages}
              setMessages={setMessages}
              setIsOpenDrawer={setIsOpenDrawer}
              isChatExist={isChatExist}
              setIsChatExist={setIsChatExist}
            />
          </>
        }
      />
    </>
  );
});

export default UserList;
